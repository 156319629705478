<template>
  <el-dialog title="发放详情" :visible.sync="show" :lock-scroll="false" :close-on-click-modal="false" width="350px" :before-close="dialogClose">
    <el-form label-width="100px">
      <el-form-item label="标题">
        {{info.title}}
      </el-form-item>
      <el-form-item label="审批单号">
        {{info.auditCode}}
      </el-form-item>
      <el-form-item label="提交时间">
        {{info.createTime}}
      </el-form-item>
      <el-form-item label="发放渠道">
        {{channelList[info.grantType] && channelList[info.grantType].name}}
      </el-form-item>
      <el-form-item label="总金额">
        {{info.totalAmount}} 元
      </el-form-item>
      <el-form-item label="成功金额">
        {{info.grantAmount}} 元
      </el-form-item>
      <el-form-item label="退回金额">
        {{info.returnAmount}} 元
      </el-form-item>
      <el-form-item label="失败金额">
        {{info.errorAmount}} 元
      </el-form-item>
      <el-form-item label="文件下载">
        <a :href="info.fileUrl" style="margin:0 10px">
          <el-button icon="el-icon-download" type="text">下载文件</el-button>
        </a>
      </el-form-item>
      <el-form-item label="备注">
        {{info.remark}}
      </el-form-item>
      <el-button @click="dialogClose">关闭</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'info',
  props: ['show', 'info'],
  data() {
    return {
      channelList: [
        { name: '身边云', id: 0 },
        { name: '嗨客', id: 1 },
        { name: '艾薪', id: 2 },
        { name: '合摩', id: 3 },
        { name: '小豆', id: 4 },
        { name: '孔朝', id: 5 },
        { name: '鑫路源', id: 6 },
        { name: '万友引利', id: 7 },
        { name: '北斗', id: 8 },
        { name: '中禧', id: 9 },
        { name: '广西微客(外部)', id: 10 },
        { name: '广西在线文化(外部)', id: 11 },
        { name: '其他', id: 12 }
      ],
    }
  },
  methods: {
    dialogClose() {
      this.$emit('update:show', false)
    },
  }
}
</script>

<style>
</style>