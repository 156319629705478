
<template>
  <div>
    <el-form label-width="95px" inline size="mini">
      <el-form-item label="搜索：">
        <el-input style="width:160px;" placeholder="请输入标题/审批单号" v-model="search.search"></el-input>
      </el-form-item>
      <OrganizationSelect v-model="search.submitOrg" @reload="getList"></OrganizationSelect>
      <el-form-item label="提交时间：">
        <el-date-picker style="width:314px;" v-model="submitTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" ">
        <el-button style="margin:0 10px 0 0" type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        <el-button size="mini" @click="exportList" type="primary" icon="el-icon-folder-opened">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 230px)">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column prop="submitOrgName" align="center" label="提交机构"></el-table-column>
      <el-table-column prop="title" align="center" label="标题"></el-table-column>
      <el-table-column prop="createTime" align="center" label="提交时间" width="150"></el-table-column>
      <el-table-column prop="createUserName" align="center" label="提交人"></el-table-column>
      <el-table-column prop="totalAmount" align="center" label="总金额"></el-table-column>
      <el-table-column prop="grantAmount" align="center" label="成功金额" width="110"></el-table-column>
      <el-table-column prop="returnAmount" align="center" label="退回金额" width="110"></el-table-column>
      <el-table-column prop="errorAmount" align="center" label="失败金额" width="110"></el-table-column>
      <el-table-column align="center" label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="text" style="color:#333" icon="el-icon-view" @click="showInfo(scope.row)">查看</el-button>
          <a :href="scope.row.fileUrl" style="margin:0 10px">
            <el-button icon="el-icon-download" type="text">下载文件</el-button>
          </a>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total"></el-pagination>
    <info :show.sync="dialogInfo" :info="info" v-if="dialogInfo"></info>
  </div>
</template>
<script>

import info from './components/info.vue'
import { getGrantRecordPage } from '@/api/issueList'
import { getCookies, exportCommon } from '@/utils/utils'
import OrganizationSelect from '@/components/OrganizationSelect.vue'
export default {
  name: 'issueListRecord',
  components: {
    OrganizationSelect, info
  },
  data() {
    return {
      search: {
        integralWithdrawalState: '',
        submitOrg: '',
        search: '',
      },
      submitTime: [],
      grantTime: [],
      list: [],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
      dialogInfo: false,
      info: {}
    }
  },
  created() {
    // this.search.submitOrg = getCookies('organizationId') == 1 ? '' : getCookies('organizationId')
    // this.getList();
  },
  methods: {
    showInfo(item) {
      this.info = item;
      this.dialogInfo = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },

    getList() {
      console.log(this.submitTime)
      let param = {
        isPage: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      Object.assign(param, this.search)
      param['submitStartTime'] = this.submitTime && this.submitTime.length ? this.submitTime[0] : '';
      param['submitEndTime'] = this.submitTime && this.submitTime.length ? this.submitTime[1] : '';

      getGrantRecordPage(param)
        .then((response) => {
          if (response.code == 200) {
            this.total = response.data.total;
            this.list = response.data.records;
          }
        })
    },

    exportList() {
      let param = Object.assign({}, this.search)
      param['submitStartTime'] = this.submitTime && this.submitTime.length ? this.submitTime[0] : '';
      param['submitEndTime'] = this.submitTime && this.submitTime.length ? this.submitTime[1] : '';
      param.submitOrg = param.submitOrg || getCookies('organizationId')
      exportCommon("/wlynGrantRecord/exportExcel", param);
    }
  },
}
</script>